import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import SwiperCore, { Navigation, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "../css/style.css"
import "../css/swiper.css"
import mfinstagram from "../images/MF-logo-instagram.jpg"
import hearth from "../images/hearth.png"
import instagramlogo from "../images/instagram.png"

SwiperCore.use([Navigation, Autoplay])

var params = {
  slidesPerView: 3,
  loop: true,
  spaceBetween: 0,
  autoplay: {
    delay: 8000,
    disableOnInteraction: false,
  },
  grabCursor: true,
}

const pageQuery = graphql`
  {
    allInstaNode(limit: 15, sort: { fields: timestamp, order: DESC }) {
      nodes {
        localFile {
          childImageSharp {
            fluid {
              base64
              src
            }
          }
        }
        caption
        likes
        id
        timestamp
        username
      }
    }
  }
`

const Instagram = () => {
  const {
    allInstaNode: { nodes },
  } = useStaticQuery(pageQuery)

  return (
    <section className="instagram-section page-border">
      <div className="instagram-user-box">
        <img
          className="instagram-logo"
          src={mfinstagram}
          alt="logo muchofuchomx instagram"
        />
        <p className="instagram-user">@muchofuchomx</p>
      </div>
      <div className="grid-container-instagram">
        <Swiper {...params} navigation className="instagram-carrousel">
          {nodes.map(({ caption, likes, localFile, timestamp, ...nodes }) => {
            return (
              <SwiperSlide>
                <Link
                  to="https://www.instagram.com/muchofuchomx/"
                  target="_blank"
                  className="instagram-card"
                >
                  <img
                    alt=""
                    className="instagram-image"
                    src={localFile.childImageSharp.fluid.src}
                  ></img>
                  <div className="instagram-card-box">
                    <p className="instagram-text">{caption}</p>
                    <div>
                      <img
                        className="instagram-likes-img"
                        src={hearth}
                        alt="hearth instagram"
                      />
                      <p className="instagram-likes">{likes}</p>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
      <div className="instagram-button-sec">
        <Link
          to="https://www.instagram.com/muchofuchomx/"
          className="instagram-button"
          target="_blank"
        >
          <img className="instagram-button-img" src={instagramlogo} alt="" />
          <p className="instagram-button-text">Síguenos en Instagram</p>
        </Link>
      </div>
    </section>
  )
}

export default Instagram
